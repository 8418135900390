<template>
    <div class="page">
        <div class="switch">
            <div @click="switchCanvas(1)" :class="type== 1 ? 'select' : ''">招工简图</div>
            <div @click="switchCanvas(2)" :class="type== 2 ? 'select' : ''">招工详图</div>
        </div>
        <div class="img1" ref="img1" v-show="type == 1">
            <div class="content">
                <img class="jobImage" src="@/assets/img/jobImage.png" alt="">
                <div class="title">高薪岗位，职等你来</div>
                <div v-if="details.wage_for_display && details.salary_for_display">
                    <div class="wage">{{details.wage_for_display}}</div>
                    <div class="salary">
                        <div>{{details.salary_for_display}}</div>
                    </div>
                </div>
                <div v-else class="oneWage">
                    <div class="wage">{{details.wage_for_display || details.salary_for_display}}</div>
                </div>
                <div class="tag" v-if="details.tag_name_list && details.tag_name_list.length">{{details.tag_list}}</div>
                <div class="bottom">
                    <div class="left">
                        <div id="qrcode1" ref="qrcode1"></div>
                    </div>
                    <div class="right">
                        <div>长 按 扫 码</div>
                        <div>查 / 看 / 更 / 多 / 职 / 位</div>
                    </div>
                </div>

            </div>

        </div>
        <div class="img2" ref="img2" v-show="type==2">
            <div class="content">
                <img class="factory_img" v-if="details.factory_imgs && details.factory_imgs.length" :src="details.factory_img_list[0]" alt="">
                <div class="info">
                    <div class="name">{{ details.factory_short_name ? details.factory_short_name : details.factory_name }}</div>
                    <div class="wage">{{details.wage_for_display && details.salary_for_display ? details.wage_for_display +' | '+ details.salary_for_display : details.wage_for_display || details.salary_for_display}}</div>
                    <div class="job">{{details.recruitment_job_name}} </div>
                    <div class="tags" v-if="details.tag_name_list && details.tag_name_list.length">
                        <div class="tag-small select" v-for="item in details.tag_name_list" :key="item.index">{{item}}</div>
                    </div>
                </div>
                <div class="block">
                    <div class="title">薪资</div>
                    <div class="text">
                        {{details.wage_for_display ? '工价：'+details.wage_for_display+'；' : ''}}
                        {{details.wage_desc ? '工价说明：'+details.wage_desc+'；' : ''}}
                        {{details.salary_for_display ? '综合薪资：'+details.salary_for_display+'；' : ''}}
                        {{details.wage_period_desc ? '发薪周期：'+details.wage_period_desc+'；' : ''}}
                        {{details.payday ? '发薪日期：'+ (details.wage_period == 'week' ? '周' + weekList[details.payday - 1] : details.payday + '号') +'；' : ''}}
                        {{details.welfare ? '福利信息：'+details.welfare+'；' : ''}}
                    </div>
                </div>
                <div class="block">
                    <div class="title">工作内容</div>
                    <pre class="text">{{details.job_content}}</pre>
                </div>
                <div class="block">
                    <div class="title">岗位要求</div>
                    <div class="text">
                        <div v-if="details.min_age">年龄：{{details.min_age+' - '+ details.max_age}}</div>
                        <div v-if="details.sex_desc">性别：{{details.sex_desc}}</div>
                        <div v-if="details.note">其他要求：{{details.note}}</div>
                    </div>
                </div>
                <div class="block" v-if="details.factory_intro">
                    <div class="title">企业简介</div>
                    <pre class="text">{{details.factory_intro}}</pre>
                </div>
                <div class="bottom">
                    <div class="left">
                        <div>长 / 按 / 扫 / 码</div>
                        <div>查 看 更 多 职 位</div>
                    </div>
                    <div class="right">
                        <div id="qrcode" ref="qrcode"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order_img">
            <img v-if="type==1" :src="imgUrl" alt="" class="oneImage">
            <img v-else :src="imgUrl2" alt="">
            <div :class="[type==1 ? 'tips_text':'tips_text']">长按图片可保存到手机或转发至微信！</div>
        </div>
    </div>
</template>
<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas"
export default {
    data() {
        return {
            type: 1,
            weekList: ['一', '二', '三', '四', '五', '六', '日'],
            details: {},
            info: {},
            imgUrl: '',
            imgUrl2: '',
            qrcodeContent: ''
        }
    },
    mounted() {
        this.details = this.$route.params.details ? JSON.parse(this.$route.params.details) : {}
        if(this.details.tag_name_list && this.details.tag_name_list.length){
            var arr = []
            var num = 20
            this.details.tag_name_list.filter((v)=>{
                num -= v.length
                if(num > 0){
                    arr.push(v)
                }
            })
            this.details.tag_list = arr.toString().replace(/,/g, ' | ')
        }
        this.info = this.$route.params.info ? JSON.parse(this.$route.params.info) : {}
        this.qrcode()
        this.$nextTick(() => {
            this.switchCanvas(this.type)
        })
    },
    methods: {
        switchCanvas(type) {
            this.type = type
            if(this.imgUrl && this.imgUrl2){
                return false
            }
            var ref = type == 1 ? this.$refs.img1 : this.$refs.img2
            this.qrcode()
            setTimeout(()=>{
                this.$nextTick(() => {
                    html2canvas(ref, { scale: 2, useCORS: true }).then(canvas => {
                        let dataURL = canvas.toDataURL("image/png");
                        if (!this.imgUrl || !this.imgUrl2) {
                            if (type == 1) {
                                this.imgUrl = dataURL
                            } else {
                                this.imgUrl2 = dataURL
                            }
                        }
                    });
                })
            },300)
            
        },
        qrcode() {
            // let that = this;
            var link
            if (sessionStorage.getItem('introducer_id')) {
                link = process.env.VUE_APP_BASE_URL + `recruitmentDetails?m_id=${this.info.member_id}&rid=${this.details.recruitment_id}&int_id=` + sessionStorage.getItem('introducer_id')
            } else if (this.info.jobseeker_id) {
                link = process.env.VUE_APP_BASE_URL + `recruitmentDetails?m_id=${this.info.member_id}&rid=${this.details.recruitment_id}&jobseek_id=${this.info.jobseeker_id}&source=` + sessionStorage.getItem('source')
            } else {
                link = process.env.VUE_APP_BASE_URL + `recruitmentDetails?m_id=${this.info.member_id}&rid=${this.details.recruitment_id}&source=` + sessionStorage.getItem('source')
            }
            if (this.type == 1) {
                this.$refs.qrcode1.innerHTML = ''
                new QRCode(this.$refs.qrcode1, {
                    width: 60,
                    height: 60, // 高度
                    text: link, // 二维码内容
                    render: "" // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                    // background: '#f0f',   // 背景色
                    // foreground: '#ff0'    // 前景色
                });
            } else {
                this.$refs.qrcode.innerHTML = ''
                new QRCode(this.$refs.qrcode, {
                    width: 84,
                    height: 84, // 高度
                    text: link, // 二维码内容
                    render: "" // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                    // background: '#f0f',   // 背景色
                    // foreground: '#ff0'    // 前景色
                });
            }


        },
    }
}
</script>
<style scoped>
.page {
    min-height: 100vh;
    background-color: #fff;
}
.switch {
    display: flex;
    position: relative;
    z-index: 2;
    justify-content: center;
    padding-top: 50px;
}
.switch > div {
    width: 170px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border: 1px solid #cecece;
    font-size: 26px;
    color: #999;
}
.switch > div:first-of-type {
    border-radius: 10px 0 0 10px;
    border-right-width: 0;
}
.switch > div:last-of-type {
    border-radius: 0 10px 10px 0;
    border-left-width: 0;
}
.switch > div.select {
    color: #0abbb9;
    border-color: #0abbb9;
    background-color: #f8ffff;
    border-right-width: 1px;
    border-left-width: 1px;
}
.img1 {
    position: absolute;
    width: 100%;
    height: 1120px;
    top: -10000px;
    /* margin-top: -200000px; */
}
.img1 .content {
    position: relative;
}
.img1 .jobImage {
    width: 100%;
    position: absolute;
}
.img1 .title {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    position: absolute;
    top: 100px;
    width: 100%;
    text-align: center;
}
.img1 .wage {
    font-weight: 700;
    font-size: 40px;
    color: #0abbb9;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 260px;
}
.img1 .oneWage .wage{
    font-size: 50px;
    top: 320px;
}
.img1 .salary {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 330px;
}
.img1 .salary div {
    font-weight: 700;
    color: #fff;
    background: #0abbb9;
    width: 540px;
    line-height: 80px;
    height: 80px;
    border-radius: 10px;
    text-align: center;
    font-size: 50px;
    margin: auto;
}
.img1 .tag {
    position: absolute;
    top: 436px;
    font-size: 26px;
    font-weight: 700;
    width: 100%;
    text-align: center;
}
.img1 .bottom {
    position: absolute;
    top: 790px;
    left: 116px;
    width: 100%;
    display: flex;
}
.img1 .right {
    position: absolute;
    left: 140px;
    top: 30px;
}
.img1 .right > div:first-of-type {
    font-weight: 700;
    font-size: 24px;
    color: #0abbb9;
    margin-bottom: 10px;
}
.img1 .right > div:last-of-type {
    font-size: 18px;
    background-color: #0abbb9;
    color: #fff;
    width: 220px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 4px;
}
.img1 .left >>> img {
    width: 100%;
    width: 120px;
    position: absolute;
    height: 120px;
    padding: 10px;
    border: 1px solid #0abbb9;
    border-radius: 10px;
}

.img2 {
    background-color: #0abbb9;
    padding: 30px 30px 70px;
    margin: 50px;
    position: absolute;
    top: -500000px;
}
.img2 .content {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}
.img2 .content .factory_img {
    height: 260px;
    width: 100%;
    object-fit: cover;
}
.img2 .content .info,
.img2 .content .block,
.img2 .content .bottom {
    padding: 0 30px;
}
.img2 .content .info .name {
    font-weight: 700;
}
.img2 .content .info .wage {
    color: #0abbb9;
    font-size: 26px;
    font-weight: 700;
}
.img2 .content .info .job {
    color: #999;
    font-size: 24px;
}
.img2 .content .block .title {
    color: #0abbb9;
    font-size: 26px;
    color: #0abbb9;
    margin-top: 60px;
    font-weight: 700;
}
.img2 .content .block .text {
    color: #666;
    font-size: 24px;
    margin-top: 10px;
    line-height: 33px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.img2 .content .block .text > div {
    font-size: 24px;
}
.img2 .content .bottom {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 168px;
    bottom: 30px;
}
.img2 .content .bottom .left {
    position: absolute;
    bottom: 0;
}
.img2 .content .bottom .left > div:first-of-type {
    color: #0abbb9;
    border: 1px solid #0abbb9;
    width: 180px;
    line-height: 54px;
    height: 54px;
    font-size: 24px;
    padding: 0 16px;
    border-radius: 10px;
}
.img2 .content .bottom .left > div:last-of-type {
    color: #333;
}
.img2 .content .bottom .right {
    width: 168px;
    height: 168px;
    position: absolute;
    right: 30px;
}
.img2 .content .bottom .right >>> img {
    width: 100%;
}
.order_img {
    position: relative;
}
.order_img img {
    margin: 50px;
    width: calc(100% - 100px);
}
.order_img .oneImage {
    margin: 0;
    width: 100%;
}

.tips_text {
    font-size: 20px;
    color: #fff;
    position: absolute;
    bottom: 126px;
    text-align: center;
    width: 100%;
}
.tips_text1 {
    font-size: 20px;
    color: #fff;
    position: absolute;
    bottom: 80px;
    text-align: center;
    width: 100%;
}
</style>